import styles from "../../page.module.css";
import broom_icon from "../../icons/broom_icon.svg";
import bin_icon from "../../icons/bin_icon.svg";
import supply_icon from "../../icons/supply_icon.svg";
import vacuum_icon from "../../icons/vacuum_icon.svg";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import Carousel from "../../components/Carousel/Carousel";

const cardContent = [
  {
    title: "Gebäudereinigung",
    text: "Egal ob Büro, Praxis, Treppen-häuser oder dazugehörige (Hinter-)Höfe. Wir übernehmen die Reinigung Ihres Objektes!",
    icon: supply_icon,
  },
  {
    title: "Treppenreinigung",
    text: "Durch die tägliche Nutzung, müssen Treppen gepflegt und gereinigt werden. Dies können wir für Sie ordnunggemäß übernehmen!",
    icon: broom_icon,
  },
  {
    title: "Büroraumreinigung",
    text: "Wir blicken auf eine über 10-jährige Erfahrung in der Büroraumreinigung zurück. Die diskrete und gründliche Reinigung ist hierbei unsere oberste Priorität!",
    icon: vacuum_icon,
  },
  {
    title: "Hausmeisterdienst",
    text: "Die Instandhaltung, kleinere Reperaturarbeiten und weitere Arbeiten am jeweiligen Objekt können wir problemlos bewerkstelligen!",
    icon: bin_icon,
  },
];

export default function Home() {
  return (
    <main className={styles.main}>
      <div className="pb-72 flex flex-col justify-center items-center">
        <h1 className="text-3xl whitespace-nowrap text-center">
          Willkommen bei
        </h1>
        <h1 className="text-3xl whitespace-nowrap font-bold text-center">
          SZK Cleaning
        </h1>
        <Link to="/kontakt">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Jetzt kontaktieren
          </button>
        </Link>

        <h2 className="text-xl mt-8 text-center">
          - Unsere Dienstleistungen -
        </h2>
        <div className="flex sm:w-[610px] flex-row flex-wrap justify-center items-center my-8">
          {cardContent.map((card, i) => (
            <div
              key={i}
              className="flex justify-center items-center basis-[50%]"
            >
              <Card title={card.title} text={card.text} icon={card.icon} />
            </div>
          ))}
        </div>
        <Carousel />
      </div>
    </main>
  );
}
