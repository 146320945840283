import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.svg";

import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="bg-sky-600 px-6 sm:px-10 py-2.5 dark:bg-gray-900 fixed w-full z-20 top-0 left-0 dark:border-b border-gray-200 dark:border-gray-600 shadow-md">
      <div className="container flex flex-wrap items-center justify-between max-w-none ">
        <Link to="/" className="flex items-center justify-start">
          <img src={logo} className="w-16 mr-3 " alt="SZK Cleaning Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
            SZK Cleaning
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-sticky"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-300 dark:focus:ring-gray-600"
          aria-controls="navbar-sticky"
          aria-expanded={menuOpen}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="black"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`${
            menuOpen ? "block" : "hidden"
          } items-center justify-between w-full md:flex md:w-auto md:order-1`}
          id="navbar-sticky"
        >
          <ul className="flex items-center  flex-col p-4 mt-4 border border-sky-600 rounded-lg bg-white md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-sky-600 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <a onClick={closeMenu}>
                <ThemeSwitch />
              </a>
            </li>
            <li>
              <Link
                to="/"
                onClick={toggleMenu}
                className="block py-2 pl-3 pr-4 text-xl text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-white md:hover:text-gray-300 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Startseite
              </Link>
            </li>
            <li>
              <Link
                to="/kontakt"
                onClick={toggleMenu}
                className="block py-2 pl-3 pr-4 text-xl text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-white md:hover:text-gray-300 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Kontakt
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={toggleMenu}
                className="block py-2 pl-3 pr-4 md:mr-4 text-xl text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:text-white md:hover:text-gray-300 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              >
                Über uns
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
