import CookieDialog from "../CookieDialog/CookieDialog";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";

function Layout(props) {
  return (
    <div className="dark:bg-gray-700 dark:text-white">
      <NavBar />
      {props.children}
      <CookieDialog />
      <Footer />
    </div>
  );
}

export default Layout;
