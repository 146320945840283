import { useEffect, useState } from "react";
import Image1 from "../../imgs/carousel_img1.jpg";
import Image2 from "../../imgs/carousel_img2.jpg";
import Image3 from "../../imgs/carousel_img3.jpg";
import Image4 from "../../imgs/carousel_img4.jpg";

const images = [Image1, Image2, Image3, Image4];

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (currentImageIndex) => (currentImageIndex + 1) % images.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex]); // dependency array?

  return (
    <div className="relative mt-8 ">
      <img
        className="object-cover mx-auto w-[300px] h-[200px] sm:w-[800px] sm:h-[400px] border-black border-2 dark:border-white"
        src={images[currentImageIndex]}
        alt={`Bild ${currentImageIndex + 1}`}
      />
    </div>
  );
};

export default Carousel;
