import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/home";
import About from "./pages/About/about";
import Layout from "./components/Layout/Layout";
import Kontakt from "./pages/kontakt/kontakt";
import Datenschutz from "./pages/datenschutz/datenschutz";
import Impressum from "./pages/impressum/impressum";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <div className="dark:bg-gray-700 dark:text-white">
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
