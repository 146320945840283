import { useEffect, useState } from "react";
import themeswitch from "../../icons/themeswitch_icon.svg";
import themeswitch_dark from "../../icons/themeswitch_icon_dark.svg";

const ThemeSwitch = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(document.documentElement.classList.contains("dark"));
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle("dark");
    console.log(document.documentElement.classList); // check if "dark" class is applied
  };

  const themeImage = isDarkMode ? themeswitch_dark : themeswitch;
  const themeAlt = isDarkMode ? "Lichtmodus" : "Dunkelmodus";

  return (
    <button className="w-8 h-8" onClick={toggleTheme}>
      <img src={themeImage} alt={themeAlt} />
    </button>
  );
};

export default ThemeSwitch;
