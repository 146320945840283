import React from "react";

export default function Paragraph({ text }) {
  return (
    <>
      <p className="font-light text-gray-800 dark:text-gray-400">{text}</p>
      <br />
    </>
  );
}
