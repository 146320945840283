import styles from "../../page.module.css";

function About() {
  return (
    <main className={styles.main}>
      <div className=" pb-72 sm:left-40 max-w-[800px] min-w-[280px]">
        <h1 className="mb-3 text-2xl font-light  text-gray-800 md:text-3xl dark:text-gray-400">
          Über uns.
        </h1>
        <p className="max-w-[450px] lg:max-w-[700px] font-light text-gray-800 dark:text-gray-400">
          SZK Cleaning ist ein junges Familienunternehmen, das auf eine große
          Erfahrung im Bereich des Reinigungsservice zurückblicken kann.
          Gründlichkeit, Diskretion und Kundenzufriedenheit waren dabei stets
          unsere höchste Priorität. Um diese Anforderungen erfüllen zu können,
          stehen Sie als Kunde immer direkt mit uns im Kontakt und können Ihre
          Wünsche und Sorgen persönlich an uns heranbringen. Auch das
          Kennenlernen findet bei uns auf persönlicher Ebene statt, indem die
          Erstbesichtigung in einem persönlichen Treffen und konstenfrei
          vorgenommen wird. Wir freuen uns auf Ihre Fragen, Anfragen und
          sonstige Nachrichten.{<br />}
          {<br />} Ihr SZK Cleaning Team.
        </p>
      </div>
    </main>
  );
}
export default About;
