import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CookieDialog = () => {
  const [consent, setConsent] = useState(null);

  useEffect(() => {
    const getCookie = (name) => {
      if (typeof window !== "undefined") {
        const cookieValue = document.cookie
          .split("; ")
          .find((row) => row.startsWith(name));
        if (cookieValue) {
          return cookieValue.split("=")[1];
        }
      }
      return undefined;
    };

    const cookieConsent = getCookie("cookie_consent");

    if (cookieConsent) {
      setConsent(cookieConsent);
    } else {
      setConsent(null);
    }
  }, []);

  const setCookie = (name, value, days) => {
    if (typeof window !== "undefined") {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
  };

  const handleAcceptAll = () => {
    setConsent("all");
    setCookie("cookie_consent", "all", 30);
  };

  const handleAcceptEssentials = () => {
    setConsent("essential");
    setCookie("cookie_consent", "essential", 30);
  };

  const handleDecline = () => {
    setConsent("none");
    setCookie("cookie_consent", "none", 30);
  };

  if (consent) {
    return null;
  }

  return (
    <div className="fixed w-full bottom-0 left-0 z-50 p-3 font-light text-white  bg-gray-600 border-gray-900 border-2">
      <h1 className="flex justify-center text-xl font-semibold mt-3">
        Zusätzliche Datenschutzinformationen
      </h1>
      <div className="flex justify-center mt-3">
        <Link
          to="/impressum"
          className="block mr-1 text-sky-500 hover:text-gray-300  md:dark:hover:text-white  dark:hover:text-white "
        >
          Impressum |
        </Link>
        <Link
          to="/datenschutz"
          className="block mr-4  text-sky-500 hover:text-gray-300  md:dark:hover:text-white  dark:hover:text-white "
        >
          Datenschutz
        </Link>
      </div>
      <p className="mt-3">
        Diese Webseite verwendet Cookies, um Inhalte und Anzeigen zu
        personalisieren, Funktionen für soziale Medien anbieten zu können und
        die Zugriffe auf unsere Website zu analysieren.
      </p>
      <div className="flex flex-wrap justify-center">
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={handleDecline}
        >
          Ablehnen
        </button>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={handleAcceptAll}
        >
          Alle zulassen
        </button>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={handleAcceptEssentials}
        >
          Nur Erforderliche
        </button>
      </div>
    </div>
  );
};

export default CookieDialog;
