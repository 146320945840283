import styles from "./page.module.css";
import BigHeader from "./components/BigHeader";
import BoldParagraph from "./components/BoldParagraph";
import MediumHeader from "./components/MediumHeader";
import Paragraph from "./components/Paragraph";

export default function Datenschutz() {
  return (
    <main className={styles.main}>
      <div className=" max-w-[400px] pb-72 sm:left-40 lg:max-w-[800px] min-w-[280px]">
        <h1 className="mb-3 text-2xl font-light  text-gray-800 md:text-3xl dark:text-gray-400">
          Datenschutzerklärung
        </h1>
        <BigHeader text="1. Datenschutz auf einen Blick" />
        <MediumHeader text="Allgemeine Hinweise" />
        <Paragraph
          text="Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
Sie unserer unter diesem Text aufgeführten Datenschutzerklärung."
        />
        <MediumHeader text="Datenerfassung auf dieser Website" />
        <BoldParagraph text="Wer ist verantwortlich für die Datenerfassung auf dieser Website?" />
        <Paragraph
          text="Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen."
        />
        <BoldParagraph text="Wie erfassen wir Ihre Daten?" />
        <Paragraph
          text="Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
Daten handeln, die Sie in ein Kontaktformular eingeben.
Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme
erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten."
        />
        <BoldParagraph text="Wofür nutzen wir Ihre Daten?" />
        <Paragraph
          text="Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden."
        />
        <BoldParagraph text="Welche Rechte haben Sie bezüglich Ihrer Daten?" />
        <Paragraph
          text="Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. 
Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden."
        />
        <MediumHeader text="Analyse-Tools und Tools von Drittanbietern" />
        <Paragraph
          text="Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
allem mit sogenannten Analyseprogrammen.
Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
Datenschutzerklärung."
        />
        <BigHeader text="2. Hosting" />
        <Paragraph text="Wir hosten die Inhalte unserer Website bei folgendem Anbieter:" />
        <MediumHeader text="Externes Hosting" />
        <Paragraph
          text="Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden,
werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe
und sonstige Daten, die über eine Website generiert werden, handeln."
        />
        <Paragraph
          text="Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
Wir setzen folgende(n) Hoster ein:"
        />
        <p className="font-light text-gray-800 dark:text-gray-400">
          manitu GmbH{<br />} Welvertstraße 2{<br />} 66606 St. Wendel{<br />}{" "}
          Deutschland
        </p>
        <br />
        <BoldParagraph text="Auftragsverarbeitung" />
        <Paragraph
          text="Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
Weisungen und unter Einhaltung der DSGVO verarbeitet."
        />
        <BigHeader text="3. Allgemeine Hinweise und Pflichtinformationen" />
        <MediumHeader text="Datenschutz" />
        <Paragraph
          text="Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre 
          personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
dieser Datenschutzerklärung.
Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
und zu welchem Zweck das geschieht.
Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
möglich."
        />
        <MediumHeader text="Hinweis zur verantwortlichen Stelle" />
        <Paragraph text="Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:" />
        <p className="font-light text-gray-800 dark:text-gray-400">
          SZK-CLEANING{<br />} Quitzowstr. 138{<br />} 10559 Berlin{<br />}{" "}
          Deutschland{<br />}
          {<br />} Mobil: 0176 879 436 32 {<br />} info@szk-cleaning.de
        </p>
        <br />
        <Paragraph
          text="Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
entscheidet."
        />
        <MediumHeader text="Speicherdauer" />
        <Paragraph
          text="Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe."
        />
        <MediumHeader
          text="Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
Website"
        />
        <Paragraph
          text="Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
Absätzen dieser Datenschutzerklärung informiert."
        />
        <MediumHeader text="Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten" />
        <Paragraph
          text="Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.
Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
Verarbeitungstätigkeiten keinen Einfluss."
        />
        <MediumHeader text="Widerruf Ihrer Einwilligung zur Datenverarbeitung" />
        <Paragraph
          text="Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
Datenverarbeitung bleibt vom Widerruf unberührt."
        />
        <MediumHeader
          text="Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
Direktwerbung (Art. 21 DSGVO)"
        />
        <Paragraph
          text="WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO)."
        />
        <Paragraph
          text="WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
NACH ART. 21 ABS. 2 DSGVO)."
        />
        <MediumHeader text="Beschwerderecht bei der zuständigen Aufsichtsbehörde" />
        <Paragraph
          text="Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe."
        />
        <MediumHeader text="Recht auf Datenübertragbarkeit" />
        <Paragraph
          text="Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
verlangen, erfolgt dies nur, soweit es technisch machbar ist."
        />
        <MediumHeader text="Auskunft, Berichtigung und Löschung" />
        <Paragraph
          text="Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden."
        />
        <MediumHeader text="Recht auf Einschränkung der Verarbeitung" />
        <Paragraph
          text="Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
folgenden Fällen:"
        />
        <Paragraph
          text="Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
zu verlangen."
        />
        <Paragraph
          text="Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
eines Mitgliedstaats verarbeitet werden."
        />
        <MediumHeader text="Widerspruch gegen Werbe-E-Mails" />
        <Paragraph
          text="Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
Werbeinformationen, etwa durch Spam-E-Mails, vor."
        />
        <BigHeader text="4. Soziale Medien" />
        <MediumHeader text="Social-Media-Elemente mit Shariff" />
        <Paragraph
          text="Auf dieser Website werden Elemente von sozialen Medien verwendet (z. B. Facebook, Instagram).
Die Social-Media-Elemente können Sie in der Regel anhand der jeweiligen Social-Media-Logos erkennen.
Um den Datenschutz auf dieser Website zu gewährleisten, verwenden wir diese Elemente nur zusammen
mit der sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die auf dieser Website integrierten
Social-Media-Elemente Ihre personenbezogenen Daten schon beim ersten Betreten der Seite an den
jeweiligen Anbieter übertragen.
Erst wenn Sie das jeweilige Social-Media-Element durch Anklicken der zugehörigen Schaltfläche aktivieren,
wird eine direkte Verbindung zum Server des Anbieters hergestellt (Einwilligung). Sobald Sie das Social-
Media-Element aktivieren, erhält der jeweilige Anbieter die Information, dass Sie mit Ihrer IP-Adresse diese
Website besucht haben. Wenn Sie gleichzeitig in Ihrem jeweiligen Social-Media-Account (z. B. Facebook) 
eingeloggt sind, kann der jeweilige Anbieter den Besuch dieser Website Ihrem Benutzerkonto zuordnen.
Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
TTDSG dar. Diese Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.
Der Einsatz des Dienstes erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz
bestimmter Technologien einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO."
        />
        <MediumHeader text="Facebook" />
        <Paragraph
          text="Auf dieser Website sind Elemente des sozialen Netzwerks Facebook integriert. Anbieter dieses Dienstes ist
die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden
nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer übertragen.
Eine Übersicht über die Facebook Social-Media-Elemente finden Sie hier:"
        />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://developers.facebook.com
          <br />
          /docs/plugins/?locale=de_DE.
        </p>
        <br />
        <Paragraph
          text="Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem
Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese
Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken, während Sie in Ihrem Facebook-
Account eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem Facebook-Profil verlinken.
Dadurch kann Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
Facebook unter:"
        />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://de-de.facebook.com
          <br />
          /privacy/explanation.
        </p>
        <br />
        <Paragraph
          text="Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz
        des o. g. Dienstes auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
        TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine
        Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf
        Grundlage unseres berechtigten Interesses an einer möglichst umfassenden
        Sichtbarkeit in den Sozialen Medien. Soweit mit Hilfe des hier
        beschriebenen Tools personenbezogene Daten auf unserer Website erfasst
        und an Facebook weitergeleitet werden, sind wir und die Meta Platforms
        Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
        Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26
        DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei
        ausschließlich auf die Erfassung der Daten und deren Weitergabe an
        Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch
        Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam
        obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame
        Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
        unter:"
        />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.facebook.com
          <br />
          /legal/controller_addendum.
        </p>
        <br />
        <Paragraph
          text="Laut dieser
        Vereinbarung sind wir für die Erteilung der Datenschutzinformationen
        beim Einsatz des Facebook-Tools und für die datenschutzrechtlich sichere
        Implementierung des Tools auf unserer Website verantwortlich. Für die
        Datensicherheit der Facebook- Produkte ist Facebook verantwortlich.
        Betroffenenrechte (z. B. Auskunftsersuchen) hinsichtlich der bei
        Facebook verarbeiteten Daten können Sie direkt bei Facebook geltend
        machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
        verpflichtet, diese an Facebook weiterzuleiten. Die Datenübertragung in
        die USA wird auf die Standardvertragsklauseln der EU-Kommission
        gestützt. Details finden Sie hier:"
        />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.facebook.com
          <br />
          /legal/EU_data_transfer_addendum
        </p>
        <br />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://de-de.facebook.com
          <br />
          /help/566994660333381
        </p>
        <br />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.facebook.com
          <br />
          /policy.php.
        </p>
        <br />
        <MediumHeader text="Instagram" />
        <Paragraph
          text="Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden
angeboten durch die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
Irland.
Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem
Instagram-Server hergestellt. Instagram erhält dadurch Informationen über den Besuch dieser Website
durch Sie.
Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons
die Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser
Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von
Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine
Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten
Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen Medien.
Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an
Facebook bzw. Instagram weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand
Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung
verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich
auf die Erfassung der Daten und deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung
erfolgende Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung.
Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame
Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:"
        />

        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.facebook.com
          <br />
          /legal/controller_addendum
        </p>
        <br />
        <Paragraph
          text="Laut dieser Vereinbarung sind wir für die Erteilung
der Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und für die
datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte
(z. B. Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten können Sie
direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
verpflichtet, diese an Facebook weiterzuleiten.
Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.
Details finden Sie hier:"
        />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.facebook.com
          <br />
          /legal/EU_data_transfer_addendum
        </p>
        <br />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://help.instagram.com
          <br />
          /519522125107875
        </p>
        <br />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://de-de.facebook.com
          <br />
          /help/566994660333381
        </p>
        <br />
        <Paragraph text="Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:" />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://instagram.com
          <br />
          /about/legal/privacy/
        </p>
        <br />
        <Paragraph text="Quelle: " />
        <p className="font-light italic text-gray-800 dark:text-gray-400">
          https://www.e-recht24.de
        </p>
      </div>
    </main>
  );
}
