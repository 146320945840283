import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="fixed w-full bottom-0  bg-sky-600 px-6 sm:px-10 py-2.5 dark:bg-gray-900 dark:border-b border-gray-200 dark:border-gray-600 shadow-md">
      <div className="flex justify-around sm:justify-end items-center">
        <Link
          to="/impressum"
          className="block mr-4 text-sm text-white hover:text-gray-300 dark:text-gray-400 md:dark:hover:text-white  dark:hover:text-white "
        >
          Impressum
        </Link>
        <Link
          to="/datenschutz"
          className="block mr-4 text-sm text-white hover:text-gray-300 dark:text-gray-400 md:dark:hover:text-white  dark:hover:text-white "
        >
          Datenschutz
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
