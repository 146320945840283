import styles from "../../page.module.css";

export default function Kontakt() {
  return (
    <main className={styles.main}>
      <div className="pb-72 sm:left-40 max-w-[800px] min-w-[280px]">
        <h1 className="mb-3 text-2xl font-light  text-gray-800 md:text-3xl dark:text-gray-400">
          Kontakt
        </h1>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Sadettin Karakaya{<br />} SZK-CLEANING{<br />}
          {<br />} Quitzowstr. 138 {<br />} 10559 Berlin {<br />} {<br />} 0176
          879 436 32 {<br />} info@szk-cleaning.de
        </p>
        <a href="tel:017687943632">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Anrufen
          </button>
        </a>
        <a href="mailto:info@szk-cleaning.de?subject=Kontaktaufnahme">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-6 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Email schreiben
          </button>
        </a>
      </div>
    </main>
  );
}
