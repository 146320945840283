import styles from "../../page.module.css";

export default function Impressum() {
  return (
    <main className={styles.main}>
      <div className="pb-72 sm:left-40 max-w-[800px] min-w-[280px]">
        <h1 className="mb-3 text-2xl font-light  text-gray-800 md:text-3xl dark:text-gray-400">
          Impressum
        </h1>

        <h2 className="mb-3 text-xl font-light  text-gray-800 md:text-2xl dark:text-gray-400">
          Angaben gemäß § 5 TMG
        </h2>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Sadettin Karakaya{<br />} SZK-CLEANING (Einzelunternehmen) {<br />}{" "}
          Quitzowstr. 138 {<br />} 10559 Berlin
        </p>

        <h2 className="my-3 text-xl font-light  text-gray-800 md:text-2xl dark:text-gray-400">
          Kontakt
        </h2>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Mobil: 0176 879 436 32 {<br />} info@szk-cleaning.de
        </p>

        <h2 className="my-3 text-xl font-light  text-gray-800 md:text-2xl dark:text-gray-400">
          Umsatzsteuer-ID
        </h2>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
          {<br />} (In Bearbeitung)
        </p>

        <h2 className="my-3 text-xl font-light  text-gray-800 md:text-2xl dark:text-gray-400">
          Verbraucherstreitbeilegung / Universalschlichtungsstelle
        </h2>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h2 className="my-3 text-xl font-light  text-gray-800 md:text-2xl dark:text-gray-400">
          Quellenangabe für Medien etc.
        </h2>
        <p className="font-light text-gray-800 dark:text-gray-400">
          Icons: {<br />} https://www.svgrepo.com/{<br />} Bilder: {<br />}{" "}
          https://pixabay.com/ {<br />} Impressum & Datenschutz-Generator:{" "}
          {<br />} https://www.e-recht24.de
        </p>
      </div>
    </main>
  );
}
