const Card = ({ title, text, icon }) => {
  return (
    <div className="flex flex-col justify-center items-center w-72 h-80 p-6 m-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="mb-4">
        {icon ? (
          <img
            src={icon}
            height={46}
            width={46}
            alt="Icon"
            className="dark:invert"
          />
        ) : (
          <></>
        )}
      </div>
      <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white text-center">
        {title}
      </h5>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
        {text}
      </p>
    </div>
  );
};

export default Card;
